/* src/components/layout/Header/Header.css */
.header {
    background-color: #f8f9fa;
    height: 70px;
}

.hamburger {
    cursor: pointer;
    font-size: 24px;
}

.logo img {
    height: 50px;
}

.logout {
    cursor: pointer;
    color: #333;
}
