
.offer-preview-page.rtl {
    direction: rtl;
    text-align: right;
}

.offer-preview-banner img {
    width: 100%;
    height: auto;
}

.offer-preview-content h2  {
    margin-bottom: 1.5rem;
    color: #17295a;
    text-transform: uppercase;
}

.offer-preview-content p {
    line-height: 1.7;
    color: #17295a;
    margin-bottom: 1.5rem;
}

.offer-preview-spacing {
    padding: 50px 0;
    
}

.offer-preview-bg {
    background-image: url(../../../assets/images/preview-offer-bg.png);
}


.preview-offer-form-wrap {
    background-color: #FFFFFF;
    padding: 1rem;
    height: 500px;
    text-align: center;
    place-content: center;
}