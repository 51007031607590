body {
  margin: 0;
  font-family: "Lexend Deca", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.form-control {
  margin-bottom: 0.5rem;
}

.invalid-feedback {
  margin-bottom: 0.8rem;
}

.table th {
  color: #fff !important;
  background-color: #007cc5 !important;
}

table {
  border-radius: 1rem !important;
}

.table-brand-shadow {
  background-color: #fff;
  box-shadow: -1px 5px 15px rgb(25 25 25 / 10%);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.input-brand-shadow {
  background-color: #fff;
  box-shadow: -1px 5px 15px rgb(25 25 25 / 10%);
  border-radius: 1rem;
  padding: 0.5rem !important;
  border: none !important;
}

.required-mark {
  margin-left: 1px;
  color: #dc3545;
}