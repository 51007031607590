.offer-card-wrap img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.offer-card-wrap {
    background-color: #fff;
  box-shadow: -1px 5px 15px rgb(25 25 25 / 10%);
  border-radius: 1rem;
  padding: 0.5rem !important;
  border: none !important;
  position: relative;
}

.offer-card-status-wrap {
    position: absolute;
    top: 1rem;
    right: 1rem;
}