.dashboard-page {
    padding: 20px;
}
.card {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.table {
    margin-top: 20px;
}
