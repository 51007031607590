ul.sidebar-list {
    list-style: none;
    padding-left: 0;
}

.sidebar-item {
    padding: 0.5rem;
    transition: 0.3s ease;
}

.sidebar-item:hover {
    background-color: #efefef;
}

.sidebar-item a {
    text-decoration: none;
    color: #000;
}

li.sidebar-item svg {
    margin-right: 5px;
}

ul.submenu {
    list-style: none;
    padding-left: 1rem;
}

.sidebar-item span {
    cursor: pointer;
}